import axios from "axios"
import { DateTime } from "luxon"

export default {
  directives: {
    trackOn: {
      beforeMount(el, binding) {
        el.addEventListener(binding.arg, (e) =>
          binding.instance.trackInteractFromDirective(e, binding.instance, binding.value),
        )
      },
      beforeUnmount(el, binding) {
        el.removeEventListener(binding.arg, (e) =>
          binding.instance.trackInteractFromDirective(e, binding.instance, binding.value),
        )
      },
    },
  },
  data() {
    return {
      trackingServices: {
        bengie: 1,
        ga4: 2,
        customerio: 3,
      },
      trackingGroups: {
        all: [1, 2, 3],
        externalOnly: [2, 3],
        internalOnly: [1],
      },
      event: {
        event: null,
        properties: null,
      },
      trackingCustomer: null,
      availableEvents: null,
      trackingConsent: localStorage.getItem("gdpr_consent"),
    }
  },
  methods: {
    // Tracking methods
    trackView(page, properties, trackServices, widget = false) {
      this.event.event = "v2_" + page + "_view"
      this.event.properties = this.mergeEventProperties(properties)
      this.pushEvent(trackServices, widget)
    },
    trackInteract(page, properties, trackServices, widget = false) {
      this.event.event = "v2_" + page + "_interact"
      this.event.properties = this.mergeEventProperties(properties)
      this.pushEvent(trackServices, widget)
    },
    trackComplete(page, properties, trackServices, widget = false) {
      this.event.event = "v2_" + page + "_complete"
      this.event.properties = this.mergeEventProperties(properties)
      this.pushEvent(trackServices, widget)
    },
    trackConfirmed(page, properties, trackServices, widget = false) {
      this.event.event = "v2_" + page + "_confirmed"
      this.event.properties = this.mergeEventProperties(properties)
      this.pushEvent(trackServices, widget)
    },
    trackError(page, properties, trackServices, widget = false) {
      this.event.event = "v2_" + page + "_error"
      this.event.properties = this.mergeEventProperties(properties)
      this.pushEvent(trackServices, widget)
    },
    trackTimeout(properties, trackServices, widget = false) {
      this.event.event = "v2_timeout"
      this.event.properties = this.mergeEventProperties(properties)
      this.pushEvent(trackServices, widget)
    },
    trackInteractFromDirective(event, instance, paramsObject) {
      const params = instance[paramsObject] ?? instance.$data[paramsObject]
      this.trackInteract(params.event, params.properties, params.trackingServices)
    },

    // Identify methods
    identify(customer, trackServices) {
      this.trackingCustomer = customer
      this.pushIdentify(trackServices)
    },
    unidentify(trackServices) {
      this.pushUnidentify(trackServices)
    },

    // Push to services generic methods
    pushEvent(trackingServices, widget) {
      if (trackingServices.includes(this.trackingServices.bengie)) {
        this.pushToBengie()
      }
      if (trackingServices.includes(this.trackingServices.ga4)) {
        this.pushToGa4(widget)
      }
      if (trackingServices.includes(this.trackingServices.customerio)) {
        this.pushToCustomerIo()
      }
      this.clearEvent()
    },
    pushIdentify(trackingServices) {
      if (trackingServices.includes(this.trackingServices.customerio)) {
        this.identifyWithCustomerIo()
      }
      this.clearCustomer()
    },
    pushUnidentify(trackingServices) {
      if (trackingServices.includes(this.trackingServices.customerio)) {
        this.unidentifyWithCustomerIo()
      }
      this.clearCustomer()
    },

    // Individual methods to push to services
    pushToBengie() {
      axios.post(route(this.getRoute("app_event.store")), this.event)
    },
    pushToGa4(widget) {
      if (this.trackingConsent === "on") {
        if (widget) {
          const message = JSON.parse(JSON.stringify({ event: this.event.event, properties: this.event.properties }))
          window.parent.postMessage(message, "*")
        } else {
          const dataToPushToDataLayer = { event: this.event.event, properties: this.event.properties }

          if ( this.event.properties?.activity !== undefined) {
            // if activity is set, we are doing a test here to append it outside of the properties object as well for VWO test...
            dataToPushToDataLayer.activity = this.event.properties.activity
          }

          window.dataLayer.push(dataToPushToDataLayer)
        }
      }
    },
    pushToCustomerIo() {
      window.cio.track(this.event.event, this.event.properties)
    },
    identifyWithCustomerIoWithCioId() {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has("_cio_id")) {
        window.cio.identify({
          id: `cio_${urlParams.get("_cio_id")}`,
        })
      }
    },
    identifyWithCustomerIo() {
      window.cio.identify({
        id: this.trackingCustomer.email,
        created_at: DateTime.fromISO(this.trackingCustomer.created_at).toUnixInteger(),
        first_name: this.trackingCustomer.first_name,
        last_name: this.trackingCustomer.last_name,
        phone: this.trackingCustomer.phone,
        date_of_birth: this.trackingCustomer.date_of_birth,
        company: this.trackingCustomer.company,
      })
    },
    unidentifyWithCustomerIo() {
      _cio.reset()
    },

    // Clear down
    clearEvent() {
      this.event = {
        event: null,
        properties: null,
      }
    },
    clearCustomer() {
      this.trackingCustomer = null
    },

    // Data manipulation
    mergeEventProperties(properties) {
      return { ...this.event.properties, ...{ session: this.$page.props.session }, ...properties }
    },
  },
}
